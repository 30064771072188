import React from "react";
import SlideOne from "../media/images2/slider/SLIDER_01.jpg";
import SlideTwo from "../media/images2/slider/SLIDER_02.jpg";
import SlideThree from "../media/images2/slider/SLIDER_03.jpg";
import SlideFour from "../media/images2/slider/SLIDER_04.jpg";
import SlideFive from "../media/images2/slider/SLIDER_05.jpg";
import SlideSix from "../media/images2/slider/SLIDER_06.jpg";
import SlideOneE from "../media/images2/slider/SLIDERESP_01.jpg";
import SlideTwoE from "../media/images2/slider/SLIDERESP_02.jpg";
import SlideThreeE from "../media/images2/slider/SLIDERESP_03.jpg";
import SlideFourE from "../media/images2/slider/SLIDERESP_04.jpg";
import SlideFiveE from "../media/images2/slider/SLIDERESP_05.jpg";
import SlideSixE from "../media/images2/slider/SLIDERESP_06.jpg";
import SlideOneM from "../media/images2/slider/SLIDER_01_MOVIL.jpg";
import SlideTwoM from "../media/images2/slider/SLIDER_02_MOVIL.jpg";
import SlideThreeM from "../media/images2/slider/SLIDER_03_MOVIL.jpg";
import SlideFourM from "../media/images2/slider/SLIDER_04_MOVIL.jpg";
import SlideFiveM from "../media/images2/slider/SLIDER_05_MOVIL.jpg";
import SlideSixM from "../media/images2/slider/SLIDER_06_MOVIL.jpg";
import SlideOneME from "../media/images2/slider/SLIDERESP_01_MOVIL.jpg";
import SlideTwoME from "../media/images2/slider/SLIDERESP_02_MOVIL.jpg";
import SlideThreeME from "../media/images2/slider/SLIDERESP_03_MOVIL.jpg";
import SlideFourME from "../media/images2/slider/SLIDERESP_04_MOVIL.jpg";
import SlideFiveME from "../media/images2/slider/SLIDERESP_05_MOVIL.jpg";
import SlideSixME from "../media/images2/slider/SLIDERESP_06_MOVIL.jpg";
import Prev from "../media/images2/prev.svg";
import Next from "../media/images2/next.svg";
import { useTranslation } from "react-i18next";

import { useRef } from "react";

export default function Slider() {
  const { i18n } = useTranslation();

  const slideshow = useRef(null);

  const next = () => {
    if (slideshow.current.children.length > 0) {
      const firstElement = slideshow.current.children[0];

      slideshow.current.style.transition = `1500ms ease-out all`;
      const slideSize = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${slideSize}px)`;

      const transition = () => {
        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = `translateX(0)`;
        slideshow.current.appendChild(firstElement);
        slideshow.current.removeEventListener("transitionend", transition);
      };
      slideshow.current.addEventListener("transitionend", transition);
    }
  };
  const prev = () => {
    if (slideshow.current.children.length > 0) {
      const index = slideshow.current.children.length - 1;
      const lastElement = slideshow.current.children[index];
      slideshow.current.insertBefore(lastElement, slideshow.current.firstChild);
      slideshow.current.style.transition = "none";
      const slideSize = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${slideSize}px)`;
      setTimeout(() => {
        slideshow.current.style.transition = `1500ms ease-out all`;
        slideshow.current.style.transform = `translateX(0)`;
      }, 150);
    }
  };

  return (
    <div className="slider-container">
      <div className="sliderGp">
        <div className="container-slider">
          <div className="slider" ref={slideshow}>
          <div className="slide-container">
            <img
              src={`${i18n.language === "en" ? SlideOne : SlideOneE}`}
              className="proyecto desktop"
              alt="CORPORATIVO NACIONES UNIDAS"
            />
                        <img
              src={`${i18n.language === "en" ? SlideOneM : SlideOneME}`}
              className="proyecto mobile"
              alt="CORPORATIVO NACIONES UNIDAS"
            />
          </div>
          <div className="slide-container">
            <img src={`${i18n.language === "en" ? SlideTwo : SlideTwoE}`} className="proyecto desktop" alt="VIVIENDAS MORELOS" />
            <img src={`${i18n.language === "en" ? SlideTwoM : SlideTwoME}`} className="proyecto mobile" alt="VIVIENDAS MORELOS" />
          </div>
          <div className="slide-container">
            <img src={`${i18n.language === "en" ? SlideThree : SlideThreeE}`} className="proyecto desktop" alt="SELINA" />
            <img src={`${i18n.language === "en" ? SlideThreeM : SlideThreeME}`} className="proyecto mobile" alt="SELINA" />
          </div>
          <div className="slide-container">
            <img src={`${i18n.language === "en" ? SlideFour : SlideFourE}`} className="proyecto desktop" alt="CONDOMINIO VIDELTA" />
            <img src={`${i18n.language === "en" ? SlideFourM : SlideFourME}`} className="proyecto mobile" alt="CONDOMINIO VIDELTA" />
          </div>
          <div className="slide-container">
            <img src={`${i18n.language === "en" ? SlideFive : SlideFiveE}`} className="proyecto desktop" alt="SUNSET" />
            <img src={`${i18n.language === "en" ? SlideFiveM : SlideFiveME}`} className="proyecto mobile" alt="SUNSET" />
          </div>
          <div className="slide-container">
            <img src={`${i18n.language === "en" ? SlideSix : SlideSixE}`} className="proyecto desktop" alt="HOTEL BECQUER" />
            <img src={`${i18n.language === "en" ? SlideSixM : SlideSixME}`} className="proyecto mobile" alt="HOTEL BECQUER" />
          </div>
          </div>
        </div>
      </div>
      <div className="sliderCtrl">
        <p
          className="font-medium prev"
          onClick={() => {
            prev();
          }}
        >
         <img src={Prev}  width={20} height= {20} alt="prev" />
        </p>
        <p
          className="font-medium next"
          onClick={() => {
            next();
          }}
        >
          <img src={Next} width={20} height= {20} alt="next" />
        </p>
      </div>
    </div>
  );
}

