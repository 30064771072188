import React from "react";
import arrow from "../media/images2/arrowB.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FeaturedOne from "../media/images2/FEATURED_BANNER_NU.jpg";
import FeaturedTwo from "../media/images2/FEATURED_BANNER_MORELOS.jpg";
import FeaturedThree from "../media/images2/FEATURED_BANNER_SELINA.jpg";
import FeaturedFour from "../media/images2/FEATURED_BANNER_VIDELTHA.jpg";
import FeaturedFive from "../media/images2/FEATURED_BANNER_SUNSET.jpg";
import FeaturedSix from "../media/images2/FEATURED_BANNER_BECQUER.jpg";
export default function Projects() {
  const { t } = useTranslation();

  return (
    <div class="projects bg-beige bigContainers">
      <p className="font-book allprojects">{t("projects.allprojects")}</p>
      <div className="projects">
        <div className="fullcol">
          <div className="projectshow">
            <img
              src={FeaturedOne}
              alt="Corporativo Naciones Unidas"
              className="project"
            />
          </div>
          <div>
            <Link
              to="/projects/corporativo-naciones-unidas"
              className="subtitle-link"
            >
              <p className="font-medium">01 / {t("projects.corporate")}</p>
              <img
                src={arrow}
                alt="arrow"
                className="link-sub"
                width={15}
                height={15}
                priority
              />
            </Link>
          </div>
        </div>
        <div className="midcol">
          <div className=" ">
            <img
              src={FeaturedTwo}
              alt="VIVIENDAS MORELOS"
              className="project"
            />
          </div>
          <Link to="/projects/viviendas-morelos" className="subtitle-link">
            <p className="font-medium">02 / {t("projects.residencial")}</p>
            <img
              src={arrow}
              alt="arrow"
              className="link-sub"
              width={15}
              height={15}
              priority
            />
          </Link>
        </div>
        <div className="midcol">
          <div className=" ">
            <img src={FeaturedThree} alt="HOTEL SELINA" className="project" />
          </div>
          <Link to="/projects/hotel-selina" className="subtitle-link">
            <p className="font-medium">03 / {t("projects.hospitality")}</p>
            <img
              src={arrow}
              alt="arrow"
              className="link-sub"
              width={15}
              height={15}
              priority
            />
          </Link>
        </div>
        <div className="fullcol">
          <div className=" ">
            <img
              src={FeaturedFour}
              alt="CONDOMINIO VIDELTHA"
              className="project"
            />
          </div>
          <Link to="/projects/condominio-videltha" className="subtitle-link">
            <p className="font-medium">04 / {t("projects.residencial")}</p>
            <img
              src={arrow}
              alt="arrow"
              className="link-sub"
              width={15}
              height={15}
              priority
            />
          </Link>
        </div>
        <div className="fullcol">
          <div className=" ">
            <img src={FeaturedFive} alt="SUNSET" className="project" />
          </div>
          <Link to="/projects/sunset" className="subtitle-link">
            <p className="font-medium">05 / {t("projects.residencial")}</p>
            <img
              src={arrow}
              alt="arrow"
              className="link-sub"
              width={15}
              height={15}
              priority
            />
          </Link>
        </div>
        <div className="midcol">
          <div className=" ">
            <img src={FeaturedSix} alt="HOTEL BECQUER" className="project" />
          </div>
          <Link to="/projects/hotel-becquer" className="subtitle-link">
            <p className="font-medium">06 / {t("projects.hospitality")}</p>
            <img
              src={arrow}
              alt="arrow"
              className="link-sub"
              width={15}
              height={15}
              priority
            />
          </Link>{" "}
        </div>
      </div>
    </div>
  );
}
