import React from "react";
import Mapa from "../media/images2/INBACK_PAGCONTACTO_MAPA.jpg"
import MapaM from "../media/images2/INBACK_PAGCONTACTO_MAPA_MOVIL.jpg"

import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();
  return (
    <div className="contacto containers bg-beige">
      <p className="font-medium title">{t("services.title")}</p>
      <div class="container-5">
        <div className="info">
          <div className="col1">
            <p className="font-thin">{t("info.location")}</p>
            <p className="font-book">
              Avenida Naciones Unidas 6780,
              <br />
              Loma Real, Zapopan, Jal.
            </p>
            <p className="font-thin">{t("info.contact")}</p>
            <p className="font-book">T. +52 33 3654 8552</p>
            <p className="font-book underline"><a href="https://goo.gl/maps/WJL7VuxsPjgNR7CZ6">Get directions</a></p>
          </div>
          <div className="col2">
            <div>
              {" "}
              <p className="font-medium">{t("services.sales")}</p>
              <p className="font-book">ulises@inback.mx</p>
              <p className="font-medium">{t("services.hr")}</p>
              <p className="font-book">magda@inback.mx</p>
            </div>

            <div>
              <p className="font-medium">{t("services.legal")}</p>
              <p className="font-book">rebeca@inback.mx</p>
              <p className="font-medium">{t("services.operations")}</p>
              <p className="font-book">andrea@inback.mx</p>
            </div>

          </div>
        </div>
      </div>
      <img src={Mapa} alt="Inback Map" className="img-center desktop" priority />
      <img src={MapaM} alt="Inback Map" className="img-center mobile" priority />
    </div>
  );
}
