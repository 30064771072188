import React from "react";
import BannerOne from "../../media/images2/videltha/BANNER1.jpg";
import BannerTwo from "../../media/images2/videltha/BANNER2.jpg";
import BannerThree from "../../media/images2/videltha/BANNER3.jpg";
import BannerFour from "../../media/images2/videltha/BANNER4.jpg";
import BannerFive from "../../media/images2/videltha/BANNER5.jpg";
import BannerSix from "../../media/images2/videltha/BANNER6.jpg";
import BannerSeven from "../../media/images2/videltha/BANNER7.jpg";
import BannerEight from "../../media/images2/videltha/BANNER8.jpg";
import BannerNine from "../../media/images2/videltha/BANNER9.jpg";
import { useTranslation } from "react-i18next";
import FeaturedThree from "../../media/images2/FEATURED_BANNER_NU.jpg";
import FeaturedFour from "../../media/images2/FEATURED_BANNER_SELINA.jpg";
import { Link } from "react-router-dom";
import next from "../../media/images2/more.svg";
import { useState } from "react";

export default function Videltha() {
  const { t } = useTranslation();
  const [scroll2, setScroll2] = useState(0);

  const bannersV = [
    BannerTwo,
    BannerFive,
    BannerSix,
    BannerSeven,
    BannerEight,
    BannerNine,
  ];
  const changeScrollTwo = () => {
    scroll2 < 5 ? setScroll2(scroll2 + 1) : setScroll2(0);
  };

  return (
    <>
      <div className="projectPage bg-beige bigContainers">
        <div className="subtitle-link">
          <p className="project-title font-book">04 / {t("projects.residencial")}</p>
          <Link to="/projects/sunset" className="desktop">
            <p className="link-sub desktop">{t("projects.next")}</p>
          </Link>
          <Link to="/projects/sunset" className="mobile">
            {" "}
            <img
              src={next}
              alt="arrow"
              className="link-sub mobile"
              width={15}
              height={15}
              priority
            />
          </Link>
        </div>
        <div className="projectBanner">
          <img
            src={BannerOne}
            alt="Condominio Videltha"
            class="banner desktop"
          />
          <img
            src={BannerThree}
            alt="Condominio Videltha"
            class="banner mobile"
          />
        </div>
        <div className="doubleBanner">
          <div>
            <div className="line first uppercase">
              <p className="font-thin">{t("projects.location")}</p>
              <p className="font-medium">ZAPOPAN, JALISCO</p>
            </div>
            <div className="line uppercase">
              <p className="font-thin">{t("projects.area")}</p>
              <p className="font-medium">1,599 M2</p>
            </div>
            <div className="line uppercase">
              <p className="font-thin">{t("projects.architects")}</p>
              <p className="font-medium">{t("projects.hernandez")}</p>
            </div>
            <div className="line uppercase">
              <p className="font-thin">{t("projects.developer")}</p>
              <p className="font-medium">{t("info.brand")}</p>
            </div>
          </div>
          <div>
            <p>
              <span className="font-medium">{t("projects.status")} : </span>
              <span className="font-book">{t("projects.finished")}</span>
            </p>
            <p className="font-book project-ph">
              {t("projects.videltha.paragraph")}
            </p>
            <p className="font-book project-ph">
              {t("projects.videltha.paragraphSecond")}
            </p>
            <p className="font-book project-ph">
              {t("projects.videltha.paragraphThird")}
            </p>
          </div>
        </div>
        <div className="doubleBanner desktop">
          <div>
            <img
              src={BannerTwo}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
          <div>
            <img
              src={BannerThree}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
        </div>
        <div className="projectBanner">
          <img
            src={BannerFour}
            alt="Condominio Videltha"
            class="banner"
          />
        </div>
        <div className="doubleBanner">
          <div>
            <p className="font-medium project-st">
              {t("projects.videltha.subtitle")}
            </p>
            <p className="font-book project-ph">
              {t("projects.videltha.paragraphTwo")}
            </p>
            <p className="font-book project-ph">
              {t("projects.videltha.paragraphThree")}
            </p>
            <p className="font-book project-ph">
              {t("projects.videltha.paragraphFour")}
            </p>
          </div>
          <div className="desktop">
            <img
              src={BannerFive}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
        </div>
        <div className="doubleBanner desktop">
          <div>
            <img
              src={BannerSix}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
          <div>
            <img
              src={BannerSeven}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
        </div>
        <div className="doubleBanner desktop">
          <div>
            <img
              src={BannerEight}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
          <div>
            <img
              src={BannerNine}
              alt="Condominio Videltha"
              className="desktop"
            />
          </div>
        </div>
        <div className="projectBanner mobile">
          <img
            src={bannersV[scroll2]}
            alt="Condominio Videltha"
            class="banner"
          />
          <img
            src={next}
            alt="arrow"
            className="link-sub mobile next"
            width={15}
            height={15}
            priority
            onClick={changeScrollTwo}
          />
        </div>
      </div>
      <div className="bg-gray">
        <p className="font-medium uppercase">{t("projects.link")}</p>
        <Link to="/#contact">
          <p className="font-medium uppercase gray-button">
            {t("projects.button")}
          </p>
        </Link>
      </div>
      <div className="bg-beige bigContainers">
        <div className="subtitle-link">
          <p className="project-title font-book">{t("projects.related")}</p>
          <Link to="/projects">
            <p className="link-sub">{t("projects.more")}</p>
          </Link>
        </div>
        <div className="projects">
          <div className="fullcol">
            <img
              src={FeaturedThree}
              alt="COROPORATIVO NACIONES UNIDAS"
              className="banner"
            />
            <div className="subtitle-link">
              <span className="font-medium">01 / {t("projects.corporate")}</span>
            </div>{" "}
          </div>
          <div className="midcol">
            <img
              src={FeaturedFour}
              alt="CORP. NACIONES UNIDAS"
              className="banner"
            />
            <div className="subtitle-link">
              <span className="font-medium">03 / {t("projects.hospitality")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
