import React from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import arrow from "../media/images2/arrow.png";
import VideoBgDown from "../media/videos/INBACK_HOME_02.mp4";
import VideoBgDownEsp from "../media/videos/INBACK_HOME_02_ESP.mp4";
import VideoBgDownEspM from "../media/videos/INBACK_HOME_CONTACT_MOVIL_ESP.mp4";
import VideoBgDownM from "../media/videos/INBACK_HOME_CONTACT_MOVIL.mp4";

export default function Contact() {
  const contactForm = useRef();
  const { t, i18n } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_WORK_ID,
        contactForm.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="bg-dark">
      <div className="banner">
      <video src={`${i18n.language === "en" ? VideoBgDown : VideoBgDownEsp}`} autoPlay loop muted className="banner desktop"/>
      <video src={`${i18n.language === "en" ? VideoBgDownM : VideoBgDownEspM}`} autoPlay loop muted className="banner mobile"/>
      </div>
      <div className="containers bg-dark">
        <p className="font-medium quote">
          <span className="big-colon">“</span>
          {t("home.containerFour.quote")}
          <span className="big-colon"> ,,</span>
        </p>
        <p className="title uppercase">{t("contact.title")}</p>
        <div className="formContainer" id="contact">
        <form ref={contactForm} onSubmit={sendEmail}>
              <div className="inputs">
                <div className="input-name">
                  <div>
                    <input
                      type="text"
                      placeholder={t("work.inputs.name")}
                      name="name"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.name")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder={t("work.inputs.email")}
                      name="email"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.email")}
                    </label>
                  </div>
                </div>
                <div className="input-info">
                  <div>
                    <input
                      type="text"
                      placeholder={t("work.inputs.lastname")}
                      name="lastname"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.lastname")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="tel"
                      placeholder={t("work.inputs.phone")}
                      name="phone"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.phone")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="message">
                <textarea
                  id="message"
                  name="message"
                  rows={10}
                  className="message-input"
                  placeholder={t("work.inputs.message")}
                ></textarea>
                <div className="btn">
                  <button className="font-medium" type="submit">
                    {t("work.inputs.button")}
                  </button>
                  <img
                    src={arrow}
                    alt="arrow"
                    width={15}
                    height={15}
                    priority
                  />
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
}
