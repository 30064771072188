import React from "react";
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Projects from "./pages/projects";
import NavBar from "./components/navbar";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Footer from "./components/footer";
import Services from "./pages/services";
import Aboutus from "./pages/us";
import Home from "./pages/home";
import Naciones from "./pages/projects/naciones";
import Morelos from "./pages/projects/morelos";
import Selina from "./pages/projects/selina";
import Videltha from "./pages/projects/videltha";
import Sunset from "./pages/projects/sunset";
import Becquer from "./pages/projects/becquer";
import ScrollToTop from "./components/scrollToTop";
function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const lng = "en";
    i18n.changeLanguage(lng);
  }, []);

  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <NavBar dark={false} /> <Home></Home>
              </>
            }
          />
          <Route
            path="/projects"
            element={
              <>
                <NavBar dark={true} />
                <Projects />
              </>
            }
          />
          <Route
            path="/us"
            element={
              <>
                <NavBar dark={false} />
                <Aboutus />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <NavBar dark={true} />
                <Services />
              </>
            }
          />
          <Route
            path="/projects/corporativo-naciones-unidas"
            element={
              <>
                <NavBar dark={true} />
                <Naciones />
              </>
            }
          />
          <Route
            path="/projects/viviendas-morelos"
            element={
              <>
                <NavBar dark={true} />
                <Morelos />
              </>
            }
          />
          <Route
            path="/projects/hotel-selina"
            element={
              <>
                <NavBar dark={true} />
                <Selina />
              </>
            }
          />
          <Route
            path="/projects/condominio-videltha"
            element={
              <>
                <NavBar dark={true} />
                <Videltha />
              </>
            }
          />
          <Route
            path="/projects/sunset"
            element={
              <>
                <NavBar dark={true} />
                <Sunset />
              </>
            }
          />
          <Route
            path="/projects/hotel-becquer"
            element={
              <>
                <NavBar dark={true} />
                <Becquer />
              </>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
