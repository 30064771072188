import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import Logo from "../media/images2/logo.png";
import LogoW from "../media/images2/logo_blanco.png";
import { Link, useLocation } from "react-router-dom";
import Next from "../media/images2/NextD.svg";
import Prev from "../media/images2/PrevD.svg";

export default function NavBar({ dark = false }) {
  const navRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    const lng = "en";
    i18n.changeLanguage(lng);
  }, []);

  const showMobileMenu = () => {
    navRef.current.classList.toggle("responsive_nav");
    setShowMenu(!showMenu);
  };
  return (
    <>
      <div className="desktop">
        <nav
          className={`header ${
            dark ? "dark bg-beige" : " light bg-transparent"
          }`}
        >
          <a href="/">
            <img
              src={dark ? Logo : LogoW}
              alt="Inback Logo"
              width={100}
              height={24}
            />
          </a>{" "}
          <div className={`menuBar`}>
            <span
              className={`${
                path === "/projects" ? "font-medium" : "font-book"
              }`}
            >
              <Link to="/projects" className={`${dark ? "dark" : "light"}`}>
                {t("pages.projects")}
              </Link>
            </span>
            <span className={`${path === "/us" ? "font-medium" : "font-book"}`}>
              <Link to="/us" className={`${dark ? "dark" : "light"}`}>
                {t("pages.aboutus")}
              </Link>
            </span>
            <span
              className={`${path === "/contact" ? "font-medium" : "font-book"}`}
            >
              <Link to="/contact" className={`${dark ? "dark" : "light"}`}>
                {t("pages.services")}
              </Link>
            </span>
          </div>
          <span className="lang desktop">
            <span
              className={`langChange ${
                i18n.language === "en" ? "font-medium" : "font-book"
              }`}
              onClick={() => i18n.changeLanguage("en")}
            >
              EN
            </span>
            |
            <span
              className={`langChange ${
                i18n.language === "es" ? "font-medium" : "font-book"
              }`}
              onClick={() => i18n.changeLanguage("es")}
            >
              ES
            </span>
          </span>
        </nav>
      </div>
      <div className="mobile">
        <nav className="header dark bg-beige">
          <div className="flex">
            <a href="/">
              <img src={Logo} alt="Inback Logo" width={100} height={24} />
            </a>
            <img
              src={showMenu ? Prev : Next}
              alt="open"
              width={20}
              height={20}
              className="rotation mobile"
              onClick={showMobileMenu}
            />
          </div>

          <div className="menu" ref={navRef}>
            {" "}
            <div className="menuBar">
              <span
                className={`${
                  path === "/projects" ? "font-medium" : "font-book"
                }`}
              >
                <Link to="/projects" className="dark" onClick={showMobileMenu}>
                  {t("pages.projects")}
                </Link>
              </span>
              <span
                className={`${path === "/us" ? "font-medium" : "font-book"}`}
              >
                <Link to="/us" className="dark" onClick={showMobileMenu}>
                  {t("pages.aboutus")}
                </Link>
              </span>
              <span
                className={`${
                  path === "/contact" ? "font-medium" : "font-book"
                }`}
              >
                <Link to="/contact" className="dark" onClick={showMobileMenu}>
                  {t("pages.services")}
                </Link>
              </span>
            </div>
            <span className="lang">
              <span
                className={`langChange ${
                  i18n.language === "en" ? "font-medium" : "font-book"
                }`}
                onClick={() => i18n.changeLanguage("en")}
              >
                EN
              </span>
              |
              <span
                className={`langChange ${
                  i18n.language === "es" ? "font-medium" : "font-book"
                }`}
                onClick={() => i18n.changeLanguage("es")}
              >
                ES
              </span>
            </span>
          </div>
        </nav>
      </div>
    </>
  );
}
