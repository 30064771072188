import React from "react";
import BannerOne from "../../media/images2/corporativonu/NU_BANNER1.jpg";
import BannerTwo from "../../media/images2/corporativonu/NU_BANNER2.jpg";
import BannerThree from "../../media/images2/corporativonu/NU_BANNER3.jpg";
import BannerFour from "../../media/images2/corporativonu/NU_BANNER4.jpg";
import BannerFive from "../../media/images2/corporativonu/NU_BANNER5.jpg";
import BannerSix from "../../media/images2/corporativonu/NU_BANNER6.jpg";
import BannerSeven from "../../media/images2/corporativonu/NU_BANNER7.jpg";
import BannerEight from "../../media/images2/corporativonu/NU_BANNER8.jpg";
import { useTranslation } from "react-i18next";
import FeaturedThree from "../../media/images2/FEATURED_BANNER_BECQUER.jpg";
import FeaturedFour from "../../media/images2/FEATURED_BANNER_SUNSET.jpg";
import { Link } from "react-router-dom";
import next from "../../media/images2/more.svg";
import { useState } from "react";

export default function Naciones() {
  const { t } = useTranslation();
  const [scroll1, setScroll1] = useState(0);
  const [scroll2, setScroll2] = useState(0);
  const bannersH = [BannerOne, BannerFour, BannerSix, BannerSeven, BannerEight];
  const bannersV = [BannerTwo, BannerFive];
  const changeScrollOne = () => {
    scroll1 < 4 ? setScroll1(scroll1 + 1) : setScroll1(0);
  };
  const changeScrollTwo = () => {
    scroll2 === 0 ? setScroll2(1) : setScroll2(0);
  };

  return (
    <>
      <div className="projectPage bg-beige bigContainers">
        <div className="subtitle-link">
          <p className="project-title font-book">01 / {t("projects.corporate")}</p>
          <Link to="/projects/viviendas-morelos" className="desktop">
            <p className="link-sub desktop">{t("projects.next")}</p>
          </Link>
          <Link to="/projects/viviendas-morelos" className="mobile">
            {" "}
            <img
              src={next}
              alt="arrow"
              className="link-sub mobile"
              width={15}
              height={15}
              priority
            />
          </Link>
        </div>
        <div className="projectBanner">
          <img
            src={BannerOne}
            alt="Corporativo naciones unidas"
            class="banner desktop"
          />
          <img
            src={BannerThree}
            alt="Corporativo naciones unidas"
            class="banner mobile"
          />
        </div>
        <div className="doubleBanner">
          <div>
            <div className="line first uppercase">
              <p className="font-thin">{t("projects.location")}</p>
              <p className="font-medium">ZAPOPAN, JALISCO</p>
            </div>
            <div className="line uppercase">
              <p className="font-thin">{t("projects.area")}</p>
              <p className="font-medium">15,800 M2</p>
            </div>
            <div className="line uppercase">
              <p className="font-thin">{t("projects.architects")}</p>
              <p className="font-medium">{t("projects.hernandez")}</p>
            </div>
            <div className="line uppercase">
              <p className="font-thin">{t("projects.developer")}</p>
              <p className="font-medium">{t("info.brand")}</p>
            </div>
          </div>
          <div>
            <p>
              <span className="font-medium">{t("projects.status")} : </span>
              <span className="font-book">{t("projects.finished")}</span>
            </p>
            <p className="font-book project-ph">
              {t("projects.naciones.paragraph")}
            </p>
          </div>
        </div>
        <div className="projectBanner mobile">
          <img
            src={bannersH[scroll1]}
            alt="Corporativo naciones unidas"
            class="banner"
          />
          <img
            src={next}
            alt="arrow"
            className="link-sub mobile next"
            width={15}
            height={15}
            priority
            onClick={changeScrollOne}
          />
        </div>
        <div className="doubleBanner desktop">
          <div>
            <img src={BannerTwo} alt="Corp Naciones Unidas" className="desktop" />
          </div>
          <div>
            <img
              src={BannerThree}
              alt="Corp Naciones Unidas"
              className="desktop"
            />
          </div>
        </div>
        <div className="projectBanner desktop">
          <img
            src={BannerFour}
            alt="Corporativo naciones unidas"
            class="banner desktop"
          />
        </div>
        <div className="doubleBanner">
          <div>
            <p className="font-medium project-st">
              {t("projects.naciones.subtitle")}
            </p>
            <p className="font-book project-ph">
              {t("projects.naciones.paragraphTwo")}
            </p>
            <p className="font-book project-ph">
              {t("projects.naciones.paragraphThree")}
            </p>
            <p className="font-book project-ph">
              {t("projects.naciones.paragraphFour")}
            </p>
          </div>
          <div className="desktop">
            <img src={BannerTwo} alt="Corp Naciones Unidas" className="desktop" />
          </div>
        </div>
        <div className="projectBanner desktop">
          <img
            src={BannerSix}
            alt="Corporativo naciones unidas"
            class="banner desktop"
          />
        </div>
        <div className="projectBanner desktop">
          <img
            src={BannerSeven}
            alt="Corporativo naciones unidas"
            class="banner desktop"
          />
        </div>
        <div className="projectBanner desktop">
          <img
            src={BannerEight}
            alt="Corporativo naciones unidas"
            class="banner desktop"
          />
        </div>
        <div className="projectBanner mobile">
          <img
            src={bannersV[scroll2]}
            alt="Corporativo naciones unidas"
            class="banner"
          />
          <img
            src={next}
            alt="arrow"
            className="link-sub mobile next"
            width={15}
            height={15}
            priority
            onClick={changeScrollTwo}
          />
        </div>
      </div>
      <div className="bg-gray">
        <p className="font-medium uppercase">{t("projects.link")}</p>
        <Link to="/#contact">
          <p className="font-medium uppercase gray-button">
            {t("projects.button")}
          </p>
        </Link>
      </div>
      <div className="bg-beige bigContainers">
        <div className="subtitle-link">
          <p className="project-title font-book">{t("projects.related")}</p>
          <Link to="/projects"><p className="link-sub">{t("projects.more")}</p></Link>
        </div>
        <div className="projects">
          <div className="midcol">
            <img src={FeaturedThree} alt="HOTEL SELINA" className="banner" />
            <div className="subtitle-link">
              <span className="font-medium">06 / {t("projects.hospitality")}</span>
            </div>{" "}
          </div>
          <div className="fullcol">
            <img
              src={FeaturedFour}
              alt="CONDOMINIO VIDELTHA"
              className="banner"
            />
            <div className="subtitle-link">
              <span className="font-medium">05 / {t("projects.residencial")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
