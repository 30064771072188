import React from "react";
import Contact from "../components/contactForm";
import Slider from "../components/slider";
import { useTranslation } from "react-i18next";
import BannerTwo from "../media/images2/INBACK_HOME_BANNER2.jpg";
import arrow from "../media/images2/arrowB.svg";
import { Link } from "react-router-dom";
import Projects from "./projects";
import VideoBg from "../media/videos2/INBACK_HOME_01.mp4";
import VideoBgEsp from "../media/videos2/INBACK_HOME_01_ESP.mp4";
import VideoBgM from "../media/videos2/INBACK_HOME_MOVIL_1.mp4";
import VideoBgEspM from "../media/videos2/INBACK_HOME_MOVIL_ESP.mp4";
export default function Home() {
  const { t, i18n } = useTranslation();
  return (
    <div className="home bg-beige">
      <div>
        <video src={`${i18n.language === "en" ? VideoBg : VideoBgEsp}`} autoPlay loop muted className="banner-title banner desktop"/>
        <video src={`${i18n.language === "en" ? VideoBgM : VideoBgEspM}`} autoPlay loop muted className="banner-title banner mobile"/>
      </div>
      <div className="mid-containers">
        <div className="container-3">
          <div>
            <p className="title">
              {t("home.containerOne.titleOne")} <br />
              {t("home.containerOne.titleTwo")}{" "}
            </p>
            <p className="paragraph">{t("home.containerOne.paragraph")}</p>
          </div>
          <div>
            <img src={BannerTwo} alt="inback building" className="banner" />
          </div>
        </div>
        <div className="threecol">
          <div>
            <p className="title numbers">13 +</p>
            <p className="font-medium specs">
              {t("home.containerOne.specs.years")}
            </p>
          </div>
          <div>
            <p className="title numbers">25 +</p>
            <p className="font-medium specs">
              {t("home.containerOne.specs.alliances")}
            </p>
          </div>
          <div>
            <p className="title numbers">100,000 +</p>
            <p className="font-medium specs">
              {t("home.containerOne.specs.built")}
            </p>
          </div>
        </div>
      </div>
      <Slider />
      <div className="mid-containers">
        <div className="home-container">
          <p className="section-title">{t("home.containerTwo.aboutus")}</p>
          <div className="">
            <div className="container-2">
              <div>
                <p className="font-medium title">
                  {t("home.containerTwo.titleOne")}
                  <br />
                  {t("home.containerTwo.titleTwo")}
                </p>
              </div>
            </div>
            <div className="container-2 padding-bottom">
              <div className="container-3">
                {" "}
                <p className="font-book paragraph">
                  {t("home.containerTwo.paragraphOne")}
                </p>
                <p className="font-book paragraph">
                  {t("home.containerTwo.paragraphTwo")}
                </p>
              </div>
            </div>
            <div className="container-2">
              {" "}
              <p className="font-medium quote">
                <span className="big-colon">“</span>
                {t("home.containerTwo.quote")}
                <span className="big-colon"> ,,</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Projects />
      <div className="mid-containers">
        {" "}
        <div className="home-container">
          <p className="section-title">{t("home.containerFour.services")}</p>
          <div className="">
            <div className="container-2">
              <div>
                <p className="font-medium title">
                  {t("home.containerFour.title")}
                </p>
                <div className="list">
                  <div className="serviceList">
                    <p>{t("home.containerFour.construction")}</p>
                    <p>{t("home.containerFour.development")}</p>
                    <p>{t("home.containerFour.investment")}</p>
                  </div>
                </div>
                <p className="font-book left paragraph">
                  {t("home.containerFour.paragraph")}
                </p>
                <Link to="/services">
                  <p className="font-book btn">
                    {t("home.containerFour.learnmore")}{" "}
                    <img
                      src={arrow}
                      alt="arrow"
                      className="link-sub"
                      width={10}
                      height={10}
                      priority
                    />
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-video">
        <Contact />
      </div>
    </div>
  );
}
