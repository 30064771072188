import React from "react";
import { useTranslation } from "react-i18next";
import LogoW from "../media/images2/logo_blanco.png";
import fb from "../media/images2/fb.svg";
import lin from "../media/images2/in.svg";
import { Link } from "react-router-dom";
export default function Footer() {
  const { t } = useTranslation();

  const date = new Date();
  const printDate = date.toLocaleDateString(`${t("language")}`, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="footer bg-dark containers">
      <div className="footer-date uppercase">{printDate}</div>
      <div className="footer-cols">
        <div className="info-footer footerCol">
          <div>
            <img
              src={LogoW}
              alt="Inback Logo"
              className="logo"
              width={100}
              height={24}
              priority
            />
            <p className="font-medium">{t("info.brand")}</p>
            <p className="info-description">
              {t("info.description")} <br />
              {t("info.description2")}
            </p>
          </div>
        </div>
        <div className="links-footer desktop footerCol">
          <p className="font-thin">Links </p>
          <p className="font-book">
            {" "}
            <Link to="/us" className="light">
              {t("pages.aboutus")}
            </Link>
          </p>
          <p className="font-book">
            {" "}
            <Link to="/projects" className="light">
              {t("pages.projects")}
            </Link>
          </p>

          <p className="font-book">
            {" "}
            <Link to="/contact" className="light">
              {t("pages.services")}
            </Link>
          </p>
        </div>
        <div className="location-footer">
          <div className="footerCol">
            {" "}
            <p className="font-thin">{t("info.location")}</p>
            <p className="font-book">
              Avenida Naciones Unidas 6780,
              <br /> Loma Real, Zapopan, Jal.
            </p>
          </div>
          <div className="footerCol">
            {" "}
            <p className="font-thin">{t("info.contact")}</p>
            <p className="font-book">T. +52 33 3654 8552</p>
          </div>
        </div>
        <div className="copy">
          <div>
            <div className="socials">
              <a
                href="https://www.facebook.com/profile.php?id=100069238669565"
                className="socials"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="arrow" width={15} height={20} priority />
              </a>
              <a
                href="https://www.linkedin.com/company/inback-inversi%C3%B3n-patrimonial/"
                target="_blank"
                className="socials"
                rel="noopener noreferrer"
              >
                <img src={lin} alt="arrow" width={20} height={20} priority />
              </a>
            </div>
          </div>
          <p className="font-thin">{t("info.copy")}</p>
        </div>
      </div>
    </div>
  );
}
