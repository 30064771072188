import React from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import BannerOne from "../media/images2/INBACK_PAGABOUTUS_BANNER1.jpg";
import BannerOneEsp from "../media/images2/INBACK_PAGABOUTUS_BANNER1_ESP.jpg";
import BannerOneM from "../media/images2/INBACK_PAGABOUTUS_BANNER1_MOVIL.jpg";
import BannerOneEspM from "../media/images2/INBACK_PAGABOUTUS_BANNER1_ESP_MOVIL.jpg";
import arrow from "../media/images2/arrow.png";
import mv from "../media/images2/mv.jpg";
import BannerTwo from "../media/images2/INBACK_PAGABOUTUS_BANNER2.jpg";
import BannerTwoM from "../media/images2/INBACK_PAGABOUTUS_BANNER2_MOVIL.jpg";

export default function Aboutus() {
  const contactForm = useRef();
  const { t, i18n } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        contactForm.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="aboutus bg-beige">
      <div className="desktop">
        <img
          src={`${i18n.language === "en" ? BannerOne : BannerOneEsp}`}
          alt="Inback about us banner"
          className="banner-title banner"
          priority
        />
      </div>
      <div className="mobile">
        <img
          src={`${i18n.language === "en" ? BannerOneM : BannerOneEspM}`}
          alt="Inback about us banner"
          className="banner-title banner"
          priority
        />
      </div>
      <div>
        <div className="container-2 containers">
          <div>
            <p className="font-medium title">
              {t("us.containerOne.titleOne")}
              <br />
              {t("us.containerOne.titleTwo")}
            </p>
            <p className="font-book paragraph">
              {t("us.containerOne.paragraph")}
            </p>
          </div>
        </div>
        <div className="banner">
          <img
            src={BannerTwo}
            alt="Inback about us banner"
            className="banner desktop"
            priority
          />
          <img
            src={BannerTwoM}
            alt="Inback about us banner"
            className="banner mobile"
            priority
          />
        </div>
        <div className="container-2 containers">
          <div className="container-3">
            <p className="font-book paragraph">
              {t("us.containerTwo.paragraphOne")}
            </p>
            <p className="font-book paragraph">
              {t("us.containerTwo.paragraphTwo")}
            </p>
          </div>
        </div>
        <div className="container-2 containers">
          <div>
            {" "}
            <p className="font-medium title">
              {t("us.containerTwo.titleOne")}
              <br />
              {t("us.containerTwo.titleTwo")}
            </p>
            <p className="font-book paragraph">
              {t("us.containerTwo.paragraphThree")}
            </p>
          </div>
        </div>
        <div className="containers">
          {" "}
          <p className="subtitle font-book">{t("us.containerThree.title")}</p>
        </div>

        <div className="container-6 containers">
          <div className="col col-img">
            <img src={mv} alt="Inback Logo" className="banner" priority />
          </div>
          <div className="col">
            <p className="title font-medium uppercase">
              {t("us.containerThree.subtitleOne")}
            </p>
            <p className="font-book paragraph">
              {t("us.containerThree.mission")}
            </p>
            <p className="title font-medium uppercase">
              {t("us.containerThree.subtitleTwo")}
            </p>
            <p className="font-book paragraph">
              {t("us.containerThree.vision")}
            </p>
          </div>
        </div>
        <p className="font-medium quote containers">
          <span className="big-colon">“ </span>
          {t("us.containerThree.quote")}
          <span className="big-colon"> ,,</span>
        </p>
        <div className="contact-form bg-dark containers">
          <p className="title uppercase">{t("work.title")}</p>
          <p className="font-thin contact-info">
            {t("work.text")}
            <br />
            {t("work.textTwo")}
          </p>
          <div className="formContainer">
            <form ref={contactForm} onSubmit={sendEmail}>
              <div className="inputs">
                <div className="input-name">
                  <div>
                    <input
                      type="text"
                      placeholder={t("work.inputs.name")}
                      name="name"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.name")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder={t("work.inputs.email")}
                      name="email"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.email")}
                    </label>
                  </div>
                </div>
                <div className="input-info">
                  <div>
                    <input
                      type="text"
                      placeholder={t("work.inputs.lastname")}
                      name="lastname"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.lastname")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="tel"
                      placeholder={t("work.inputs.phone")}
                      name="phone"
                    ></input>
                    <br />
                    <label className="font-medium">
                      {t("work.labels.phone")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="message">
                <textarea
                  id="message"
                  name="message"
                  rows={10}
                  className="message-input"
                  placeholder={t("work.inputs.message")}
                ></textarea>
                <div className="btn">
                  <button className="font-medium" type="submit">
                    {t("work.inputs.button")}
                  </button>
                  <img
                    src={arrow}
                    alt="arrow"
                    width={15}
                    height={15}
                    priority
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
